import { useContext } from 'react';
import { Link } from 'gatsby';

import { t } from '../utils/i18n';
import { Page } from '../components/layout';
import LocaleContext from '../contexts/locale';

const NotFoundPage = () => {
	const { currentLocale, defaultLocale } = useContext(LocaleContext);

	return (
		<Page>
			<h1>{t('NOTFOUND_TITLE')}</h1>
			<p>{t('NOTFOUND_TEXT')}</p>
			<Link to={currentLocale !== defaultLocale ? `/${currentLocale}` : '/'}>
				<span>&larr; </span>
				<span>{t('BACK_TO_HOME')}</span>
			</Link>
		</Page>
	);
};

export default NotFoundPage;
